import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const HeroSlider = () => {
  const options = {
    items: 1,
    loop: true,
    autoplay: true,
    autoplayTimeout: 5000,
    smartSpeed: 1000,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    dots: true,
  };

  return (
    <section className="hero-area">
      <OwlCarousel className="hero-slides owl-carousel" {...options}>
        <div className="single-hero-slide d-flex align-items-center justify-content-center">
          <div className="slide-img bg-img" style={{ backgroundImage: 'url(img/bg-img/overall.jpg)' }}></div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-9">
                <div className="hero-slides-content" data-animation="fadeInUp" data-delay="100ms">
                  <div className="line" data-animation="fadeInUp" data-delay="300ms"></div>
                  <h2 data-animation="fadeInUp" data-delay="500ms">About Us</h2>
                  <p data-animation="fadeInUp" data-delay="700ms">
                  At Dudly Manor, we believe in sustainable and eco-friendly practices. Our organic farm supplies fresh produce for our farm-to-table dining experiences, and we take pride in our efforts to preserve the natural environment. Our eco-friendly initiatives include solar energy, rainwater harvesting, and waste recycling to minimize our ecological footprint.
                  </p>
                  <a href="#aboutus2" className="btn palatin-btn mt-50" data-animation="fadeInUp" data-delay="900ms">Read More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="single-hero-slide d-flex align-items-center justify-content-center">
          <div className="slide-img bg-img" style={{ backgroundImage: 'url(img/bg-img/front.jpg)' }}></div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-9">
                <div className="hero-slides-content" data-animation="fadeInUp" data-delay="100ms">
                  <div className="line" data-animation="fadeInUp" data-delay="300ms"></div>
                  <h2 data-animation="fadeInUp" data-delay="500ms">Our Story</h2>
                  <p data-animation="fadeInUp" data-delay="700ms">
                  Founded by a team of nature enthusiasts and cultural aficionados, Dudly Manor is more than just a retreat. It is a celebration of life, culture, and nature. Our journey began with a vision to create a place where people can escape the hustle and bustle of urban life and reconnect with themselves and the world around them.
                  </p>
                  <a href="#aboutus2" className="btn palatin-btn mt-50" data-animation="fadeInUp" data-delay="900ms">Read More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </OwlCarousel>
    </section>
  );
};

export default HeroSlider;
