import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const HeroSlider = () => {
  const options = {
    items: 1,
    loop: true,
    autoplay: true,
    autoplayTimeout: 5000,
    smartSpeed: 1000,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    dots: true,
  };

  return (
    <section className="hero-area">
      <OwlCarousel className="hero-slides owl-carousel" {...options}>
        {/* Services Slide */}
        <div className="single-hero-slide d-flex align-items-center justify-content-center">
          <div className="slide-img bg-img" style={{ backgroundImage: 'url(img/bg-img/gym.jpg)' }}></div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-9">
                <div className="hero-slides-content" data-animation="fadeInUp" data-delay="100ms">
                  <div className="line" data-animation="fadeInUp" data-delay="300ms"></div>
                  <h2 data-animation="fadeInUp" data-delay="500ms">Our Services</h2>
                  <p data-animation="fadeInUp" data-delay="700ms">
                    Explore our range of services designed to enhance your stay. From wellness facilities to outdoor activities, we offer experiences that cater to every guest's needs.
                  </p>
                  <a href="#services" className="btn palatin-btn mt-50" data-animation="fadeInUp" data-delay="900ms">Discover More</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Why Choose Us Slide */}
        <div className="single-hero-slide d-flex align-items-center justify-content-center">
          <div className="slide-img bg-img" style={{ backgroundImage: 'url(img/bg-img/lawn2.jpg)' }}></div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-9">
                <div className="hero-slides-content" data-animation="fadeInUp" data-delay="100ms">
                  <div className="line" data-animation="fadeInUp" data-delay="300ms"></div>
                  <h2 data-animation="fadeInUp" data-delay="500ms">Why Choose Us</h2>
                  <p data-animation="fadeInUp" data-delay="700ms">
                    Discover the reasons why Dudly Manor is the perfect destination for your next getaway. From our sustainable practices to our personalized services, we ensure an unforgettable experience for every guest.
                  </p>
                  <a href="#services" className="btn palatin-btn mt-50" data-animation="fadeInUp" data-delay="900ms">Learn More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </OwlCarousel>
    </section>
  );
};

export default HeroSlider;
