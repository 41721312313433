import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, Button, Card, Container, Row, Col } from 'react-bootstrap';

const Gallery = () => {
  const [images, setImages] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get('https://deployment-ochre-beta.vercel.app/api/images/fetch');
        setImages(response.data.images);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();
  }, []);

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  return (
    <>
      <section className="gallery-area section-padding-100" id='gallery'>
        <Container>
        <div className="section-heading text-center">
              <div className="line-"></div>
              <h2 style={{marginBottom:"20px"}}>Our Cottages</h2>
              <Row>
              <Col xs={12} sm={6} lg={4} className="mb-4">
              <a href="https://www.facebook.com/dudlymanorbnb/" className="btn palatin-btn" data-animation="fadeInUp" data-delay="900ms">Connect On Facebook</a></Col>
              <Col xs={12} sm={6} lg={4} className="mb-4">
              <a href="https://www.instagram.com/dudlymanorbb/" className="btn palatin-btn" data-animation="fadeInUp" data-delay="900ms">Connect On Instagram</a></Col>
              <Col xs={12} sm={6} lg={4} className="mb-4">
              <a href="https://api.whatsapp.com/send?phone=919897119305&text=Hi%2C%20%0A%0AWe%20would%20like%20to%20connect%20with%20Dudly%20Manor%20for%20a%20query" className="btn palatin-btn" data-animation="fadeInUp" data-delay="900ms">Connect On WhatsApp</a>
              </Col>
              </Row>
            </div>
          <Row>
            {images.map((image, index) => (
              <Col key={index} xs={12} sm={6} lg={4} className="mb-4">
                <Card className="h-100">
                  <Card.Img 
                    variant="top" 
                    src={image.url} 
                    alt={image.description || 'Gallery Image'} 
                    className="img-fluid" 
                    onClick={() => handleImageClick(image)} 
                    style={{ cursor: 'pointer', objectFit: 'cover', height: '200px' }}
                  />
                  {image.description && <Card.Body><Card.Text>{image.description}</Card.Text></Card.Body>}
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Image Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedImage && <img src={selectedImage.url} alt={selectedImage.description || 'Gallery Image'} className="img-fluid" />}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Gallery;
