import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';

// Utility function to format dates
const formatDate = (date) => {
  const options = { day: 'numeric', month: 'long', year: 'numeric' };
  return new Date(date).toLocaleDateString('en-US', options);
}

// Function to generate date options
const generateDateOptions = (start, end) => {
  const dates = [];
  const currentDate = new Date(start);
  const endDate = new Date(end);

  while (currentDate <= endDate) {
    dates.push(formatDate(new Date(currentDate)));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return dates;
}

const Header = ({ showModal, toggleModal }) => {
  const [isSticky, setIsSticky] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const [logoUrl, setLogoUrl] = useState(null); // State for logo URL
  const today = new Date();
  const nextWeek = new Date();
  nextWeek.setDate(today.getDate() + 360);

  const checkInOptions = generateDateOptions(today, nextWeek);
  const checkOutOptions = generateDateOptions(today, nextWeek);

  const adultsOptions = ['01', '02', '03', '04', '05', '06'];
  const childrenOptions = ['01', '02', '03', '04', '05'];

  const [checkIn, setCheckIn] = useState(checkInOptions[0]);
  const [checkOut, setCheckOut] = useState(checkOutOptions[1]);
  const [adults, setAdults] = useState(adultsOptions[0]);
  const [children, setChildren] = useState(childrenOptions[0]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contactNumber, setContactNumber] = useState('');

  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const response = await fetch('https://deployment-ochre-beta.vercel.app/api/logo/fetch/');
        const data = await response.json();
        setLogoUrl(data.url);
      } catch (error) {
        console.error('Error fetching logo:', error);
      }
    };

    fetchLogo();
  }, []);

  useEffect(() => {
    const navLinks = document.querySelectorAll('.classynav ul li a');
    navLinks.forEach(link => {
      link.classList.remove('active');
      if (link.getAttribute('href') === location.pathname) {
        link.classList.add('active');
      }
    });
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    toggleModal();
  };

  const handleConfirmBooking = async () => {
    const checkInDate = new Date(checkIn).toISOString();
    const checkOutDate = new Date(checkOut).toISOString();

    const bookingData = {
      checkInDate,
      checkOutDate,
      adults: parseInt(adults),
      children: parseInt(children),
      name,
      contactNumber,
      email
    };

    try {
      const response = await fetch('https://deployment-ochre-beta.vercel.app/api/bookings', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(bookingData)
      });

      if (response.status === 200 || response.status === 201) {
        window.location.href="/thank-you"
        toggleModal();
      }
      else if( response.status == 401) {
          alert('Slot is already booked, Please choose a different date');
      }
      else {
          alert('Failed to confirm booking.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while confirming your booking.');
    }
  };

  return (
    <header className={`header-area ${isSticky ? 'sticky' : ''}`}>
      {/* Welcome Message */}
<div style={{ backgroundColor: '#cb8670', color: 'white', padding: '10px', textAlign: 'center'}}>
  Welcome to Dudly Manor! Enjoy a luxurious stay in our cottage for just Rs. 11,000. Book now for an unforgettable experience!
</div>

      <div className="palatin-main-menu">
        <div className="classy-nav-container breakpoint-off">
          <div className="container">
            <nav className="classy-navbar justify-content-between" id="palatinNav">
              <a href="/" className="nav-brand"><img src={logoUrl} alt="logo" /></a>
              <div className="classy-navbar-toggler" onClick={toggleMenu}>
                <span className="navbarToggler"><span></span><span></span><span></span></span>
              </div>
              <div className={`classy-menu ${menuOpen ? 'open' : ''}`}>
                <div className="classycloseIcon" onClick={toggleMenu}>
                  <div className="cross-wrap"><span className="top"></span><span className="bottom"></span></div>
                </div>
                <div className="classynav">
                  <ul>
                    <li><a href="/">Home</a></li>
                    <li><a href="/about">About Us</a></li>
                    <li><a href="/services">Services</a></li>
                    <li><a href="/gallery">Gallery</a></li>
                    <li><a href="/contact">Contact</a></li>
                  </ul>
                  <div className="menu-btn">
                    <a onClick={handleSubmit} className="btn palatin-btn">Make a Reservation</a>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={toggleModal}>
        <Modal.Header closeButton>
          <Modal.Title>Booking Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input type="text" className="form-control" id="name" value={name} onChange={(e) => setName(e.target.value)} placeholder="Enter your name" required />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input type="email" className="form-control" id="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email" required />
            </div>
            <div className="form-group">
              <label htmlFor="mobile">Mobile Number</label>
              <input type="tel" className="form-control" id="mobile" value={contactNumber} onChange={(e) => setContactNumber(e.target.value)} placeholder="Enter your mobile number" required />
            </div>
            <div className="row">
              <div className="form-group col-6">
                <label htmlFor="select1">Check In</label>
                <select className="form-control" id="select1" value={checkIn} onChange={(e) => setCheckIn(e.target.value)}>
                  {checkInOptions.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>

              {/* Form Group */}
              <div className="form-group col-6">
                <label htmlFor="select2">Check Out</label>
                <select className="form-control" id="select2" value={checkOut} onChange={(e) => setCheckOut(e.target.value)}>
                  {checkOutOptions.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {/* Form Group */}
            <div className='row'>
              <div className="form-group col-6">
                <label htmlFor="select3">Adults</label>
                <select className="form-control" id="select3" value={adults} onChange={(e) => setAdults(e.target.value)}>
                  {adultsOptions.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>

              {/* Form Group */}
              <div className="form-group col-6">
                <label htmlFor="select4">Children</label>
                <select className="form-control" id="select4" value={children} onChange={(e) => setChildren(e.target.value)}>
                  {childrenOptions.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="btn palatin-btn" onClick={toggleModal}>Close</Button>
          <Button variant="primary" className="btn palatin-btn" onClick={handleConfirmBooking}>Send Booking Request</Button>
        </Modal.Footer>
      </Modal>
    </header>
  );
}

export default Header;
