import React from 'react';

const AboutUs = (showModal, toggleModal) => {
  return (
    <section className="about-us-area" id="aboutus2">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-lg-6">
            <div className="about-text mb-100">
              <div className="section-heading">
                <div className="line-"></div>
                <h2>Vision + Mission + Values</h2>
              </div>
             
                <p><strong>Vision:</strong> Create a harmonious retreat blending ethoes of sustainable, slow and mindful living with an appreciation of the environment around us.</p>
                
                <p><strong>Mission:</strong> Promote eco-friendly tourism, foster community development, and offer an authentic slice of rural life.</p>
                <p><strong>Values:</strong> Sustainability, hospitality, and enriching experiences that reconnect guests with nature.</p>
              
              <a onClick={toggleModal} className="btn palatin-btn mt-50" style={{color:"white"}}>Book Now</a>
            </div>
          </div>

          <div className="col-12 col-lg-6">
            <div className="about-thumbnail mb-100">
              <img src="img/bg-img/pool2.jpg" alt="About Us" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
