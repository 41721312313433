import React from 'react';

const Services = () => {
  const services = [
    { icon: 'fas fa-biking', title: 'Bike Rentals', description: 'Explore scenic trails with our geared mountain bikes available for adults and children over 14 at Rs.150/- per cycle.' },
    { icon: 'fas fa-binoculars', title: 'Farm Visits & Bird Watching', description: 'Explore serene farm landscapes and enjoy bird watching activities. Transportation can be arranged for your convenience.' },
    { icon: 'fas fa-utensils', title: 'Restaurant', description: 'Enjoy delicious meals in our cafe area, with winter dining available in the lawns or near the pool for a sunny experience. We are an organic farmstay and prepare all meals at the property. We request to be informed 24 hours.' },
    { icon: 'fas fa-spa', title: 'Massages', description: 'Relax with our home malish services, offering head, shoulder, foot, and full-body massages starting at Rs.500/- per person.' },
    { icon: 'fas fa-dumbbell', title: 'Gym & Swimming Pool', description: 'Stay fit and unwind during your stay with our state-of-the-art gym facilities and relaxing swimming pool, available for all guests.' },
    { icon: 'fas fa-hiking', title: 'Hiking', description: 'Discover picturesque paths on guided walking trails for Rs.1500/-, including a packed snack to enhance your morning adventure.' },
  ];

  return (
    <section className="services-area">
      <div className="container">
        <div className="row">
          {services.map((service, index) => (
            <div key={index} className="col-12 col-sm-6 col-lg-4">
              <div className="single-service-area mb-100 wow fadeInUp" data-wow-delay={`${100 * (index + 1)}ms`}>
                <i className={service.icon}></i>
                <h4>{service.title}</h4>
                <p>{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
