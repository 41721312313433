import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const HeroSlider = () => {
  const options = {
    items: 1,
    loop: true,
    autoplay: true,
    autoplayTimeout: 5000,
    smartSpeed: 1000,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
    dots: true,
  };

  return (
    <section className="hero-area">
      <OwlCarousel className="hero-slides owl-carousel" {...options}>
{/* Contact Slide */}
<div className="single-hero-slide d-flex align-items-center justify-content-center">
  <div className="slide-img bg-img" style={{ backgroundImage: 'url(img/bg-img/cafe.jpg)' }}></div>
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-12 col-lg-9">
        <div className="hero-slides-content" data-animation="fadeInUp" data-delay="100ms">
          <div className="line" data-animation="fadeInUp" data-delay="300ms"></div>
          <h2 data-animation="fadeInUp" data-delay="500ms">Contact Us</h2>
          <p data-animation="fadeInUp" data-delay="700ms">
            We're here to assist you. Feel free to reach out to us for any queries or assistance you may need. Whether it's about booking a stay or special requests during your visit, we're just a message away.
            You can also connect with us through our social media channels for updates and special offers.
          </p>
          <a href="#get_in_touch" className="btn palatin-btn mt-50" data-animation="fadeInUp" data-delay="1100ms">Get in Touch</a>
        </div>
      </div>
    </div>
  </div>
</div>

{/* Location Slide */}
<div className="single-hero-slide d-flex align-items-center justify-content-center">
  <div className="slide-img bg-img" style={{ backgroundImage: 'url(img/bg-img/dudly.jpg)' }}></div>
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-12 col-lg-9">
        <div className="hero-slides-content" data-animation="fadeInUp" data-delay="100ms">
          <div className="line" data-animation="fadeInUp" data-delay="300ms"></div>
          <h2 data-animation="fadeInUp" data-delay="500ms">Visit Us</h2>
          <p data-animation="fadeInUp" data-delay="700ms">
            Drop by our location to experience Dudly Manor firsthand. Located amidst the serene surroundings, our manor offers a peaceful retreat away from the hustle and bustle of city life. Explore the lush landscapes and immerse yourself in nature's beauty.
            Our friendly staff awaits to welcome you and ensure you have a memorable stay with us.
          </p>
          <a href="#map" className="btn palatin-btn mt-50" data-animation="fadeInUp" data-delay="1100ms">See Map</a>
        </div>
      </div>
    </div>
  </div>
</div>

      </OwlCarousel>
    </section>
  );
};

export default HeroSlider;
