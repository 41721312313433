import React, { useEffect } from 'react';
import Header from '../components/Header';
import Thanks from '../components/Thanks';
import Footer from '../components/Footer';

const Home = ({ showModal, toggleModal, transactionValue, currency }) => {
  useEffect(() => {
    // Dynamically pass values to the Google tag snippet
    const gtmScript = document.createElement('script');
    gtmScript.innerHTML = `
      gtag('event', 'conversion', {
        'send_to': 'AW-16674271318/3mDxCIewmMsZENbg9I4-',
        'value': ${transactionValue || 1.0}, 
        'currency': '${currency || 'INR'}'
      });
    `;
    document.head.appendChild(gtmScript);
  }, [transactionValue, currency]); // Dependency array to rerun the effect if these values change

  return (
    <div>
      <Header showModal={showModal} toggleModal={toggleModal} />
      <Thanks showModal={showModal} toggleModal={toggleModal} />
      {/* Add more sections as needed */}
      <Footer showModal={showModal} toggleModal={toggleModal} />
    </div>
  );
};

export default Home;
