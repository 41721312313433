import React from 'react';
import handleSubmit from '../components/Header'
const SwimmingPool = ({ showModal, toggleModal }) => {
  return (
    <section
      className="pool-area section-padding-100 bg-img bg-fixed"
      style={{ backgroundImage: 'url(img/bg-img/pool.jpeg)' }}
    >
      <div className="container">
        <div className="row justify-content-end">
          <div className="col-12 col-lg-7">
            <div
              className="pool-content text-center wow fadeInUp"
              data-wow-delay="300ms"
            >
              <div className="section-heading text-center white">
                <div className="line"></div>
                <h2>Swimming Pool</h2>
                <p>
                  Immerse yourself in tranquility at Dudly Manor's inviting swimming pool. Delight in delicious meals served in our cozy cafe area, or during winter, enjoy your food in the lush lawns or poolside to soak up the winter sun. Parents, please accompany your children in the pool, as there are no lifeguards. Ensure you shower before entering and only swim if you're confident in your abilities. Eating and drinking in the pool are not permitted, but our staff can set up a sun table nearby for your convenience. Proper swimming attire is required for all guests.
                </p>
              </div>
              <div className="row">
                <div className="col-12 col-sm-4">
                  <div className="pool-feature">
                    <i className="fas fa-cocktail"></i>
                    <p>Pool Beachbar</p>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="pool-feature">
                    <i className="fas fa-swimming-pool"></i>
                    <p>Swimming Pool</p>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="pool-feature">
                    <i className="fas fa-umbrella-beach"></i>
                    <p>Sunbeds</p>
                  </div>
                </div>
              </div>
              <a onClick={toggleModal} style={{color: "white"}} className="btn palatin-btn mt-50">
                Book Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SwimmingPool;
