import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import HeroSlider from '../components/Hero';
import InfinityPool from '../components/InfinityPool';
import Products from '../components/Products';
import Contact from '../components/Contact';
import WhyChooseUs from '../components/WhyChooseUs';
import Cottages from '../components/Cottage';
import BookNowForm from '../components/BookNow';

const Home = ({ showModal, toggleModal }) => {
  
  return (
    <div>
      <Header showModal={showModal} toggleModal={toggleModal}/>
      {/* Hero Area */}
      <HeroSlider showModal={showModal} toggleModal={toggleModal}/>
      <BookNowForm showModal={showModal} toggleModal={toggleModal}/>
      <WhyChooseUs showModal={showModal} toggleModal={toggleModal}/>
      <InfinityPool showModal={showModal} toggleModal={toggleModal}></InfinityPool>
      <Products showModal={showModal} toggleModal={toggleModal}></Products>
      <Cottages showModal={showModal} toggleModal={toggleModal}/>
      <Contact showModal={showModal} toggleModal={toggleModal}></Contact>
      <Footer showModal={showModal} toggleModal={toggleModal} />
    </div>
  );
}

export default Home;
