import React from 'react';

const CoreFeatures = () => {
  const features = [
    {
      image: 'img/bg-img/lawn.jpeg',
      icon: 'fas fa-sun',
      title: 'Holistic Wellness',
      description: 'We also organise Jungle Safaris at The Rajaji Tiger Reserve from mid Novemeber to mid June..'
    },
    {
      image: 'img/bg-img/lawn3.jpeg',
      icon: 'fas fa-spa', // Updated spa icon
      title: 'Traditional Indian Malish',
      description: 'Experience serenity at our tranquil spa retreat, where expert therapists provide rejuvenating treatments to soothe your senses.'
    },
    {
      image: 'img/bg-img/cottage2.jpeg',
      icon: 'fas fa-tree',
      title: 'Chic Lounge Experience',
      description: 'Indulge in a chic lounge experience, savoring handcrafted cocktails and premium beverages amidst sophisticated surroundings.'
    },
  ];

  return (
    <section className="core-features-area">
      <div className="container">
        <div className="row justify-content-center">
          {features.map((feature, index) => (
            <div key={index} className="col-12 col-md-6 col-lg-4">
              <div className="single-core-feature mb-100">
                <div className="bg-thumbnail bg-img" style={{ backgroundImage: `url(${feature.image})` }}></div>
                <div className="feature-content">
                  <i className={feature.icon}></i>
                  <h3>{feature.title}</h3>
                  <p>{feature.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CoreFeatures;
