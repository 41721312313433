import React from 'react';

const Milestones = () => {
  return (
    <section
      className="our-milestones section-padding-100-0 bg-img bg-overlay bg-fixed"
      style={{ backgroundImage: 'url(img/bg-img/dudly.jpg)' }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-8">
            <div className="section-heading text-center white">
              <div className="line-"></div>
              <h2>Our Milestones</h2>
              <p>
                Since our inception, Dudly Manor has achieved remarkable
                milestones. We've transformed our family farmhouse into a
                thriving eco-friendly retreat, hosting over 100+ satisfied
                guests. Our commitment to sustainability includes successful
                implementation of solar water heating, rainwater harvesting, and
                large-scale vermicomposting. We've fostered community
                development by employing and training local villagers, enriching
                their lives and preserving cultural heritage.
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-sm-6 col-lg-3">
            <div
              className="single-cool-fact mb-100 wow fadeInUp"
              data-wow-delay="300ms"
            >
              <div className="scf-text">
                <i className="fas fa-cocktail"></i>
                <h2>
                  <span className="counter">25+</span>
                </h2>
                <p>
                  High Tea/ Evening Snacks + 
                  <br />
                  Refreshing Drinks
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-6 col-lg-3">
            <div
              className="single-cool-fact mb-100 wow fadeInUp"
              data-wow-delay="700ms"
            >
              <div className="scf-text">
                <i className="fas fa-hotel"></i>
                <h2>
                  <span className="counter">2</span>
                </h2>
                <p>
                  Cottages with 
                  <br />
                  Single, Double and Mega Rooms
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-6 col-lg-3">
            <div
              className="single-cool-fact mb-100 wow fadeInUp"
              data-wow-delay="900ms"
            >
              <div className="scf-text">
                <i className="fas fa-swimmer"></i>
                <h2>
                  <span className="counter">10+</span>
                </h2>
                <p>
                  Stress Relief Activities 
                  <br />
                  like Gym, Pool, Cycling etc.
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-6 col-lg-3">
            <div
              className="single-cool-fact mb-100 wow fadeInUp"
              data-wow-delay="900ms"
            >
              <div className="scf-text">
                <i className="fas fa-users"></i>
                <h2>
                  <span className="counter">100+</span>
                </h2>
                <p>
                  No. of Satisfied 
                  <br />
                  Guests Served 
                  <br />
                  Till Date
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Milestones;
