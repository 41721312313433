import React from 'react';
import '../css/product.css'; // Assuming you have a CSS file for styles

const Products = () => {
  const highTeaItems = [

    { name: 'Paneer/ Mushroom Patty', price: 'Rs. 50/-', description: '(per piece)' },
    { name: 'Cream Roll', price: 'Rs. 50/-', description: '(per roll) (requires 24 hour notice)' },
    { name: 'Shami Kebabs', price: 'Veg – Rs. 300/-', description: '(per plate-6 pieces); Non-Veg – Rs. 350/- (per plate-6 pieces)' },
    { name: 'Seekh Kebab (non veg)', price: 'Rs. 350/-', description: '' },
    { name: 'Chicken Tikka', price: 'Rs. 400/-', description: '(8 pieces)' },
    { name: 'Veg Cutlets', price: 'Rs. 200/-', description: '(4 pieces)' },
    { name: 'Paneer Tikkas', price: 'Rs. 350/-', description: '(8 pieces)' },

  ];

  const drinksItems = [
    { name: 'Fresh Buttermilk', price: 'Rs.50/-', description: 'per glass' },
    { name: 'Shikanji', price: 'Rs.70/-', description: '(if with water); Rs. 100/- (if with soda)' },
    { name: 'Tea', price: 'Rs. 50/-', description: 'any flavoured tea' },
    { name: 'Iced Tea', price: 'Rs.100/-', description: 'per glass' },
    { name: 'Plain Milk', price: 'Rs.80/-', description: '(fresh cow milk from our own organic dairy)' },
    { name: 'Cold Coffee', price: 'Rs.125/-', description: '' },
    { name: 'Black Coffee', price: 'Rs.50/-', description: '' },
    { name: 'Milk Coffee', price: 'Rs.75/-', description: '' },
  ];

  return (
    <section className="rooms-area section-padding-100-0">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-lg-6">
            <div className="section-heading text-center">
              <div className="line"></div>
              <h2>Our Menu</h2>
              <p> We are an organic farmstay and prepare all meals at the property, we request to be informed 24 hours prior for the meals that you would be interested in booking with us. We further request you to share your meal preferences. On special request we also provide a specially curated PAHADI and UP style meals.</p>
              <br></br>
              <p>We also provide high tea/ evening snacks, which are chargeable separately. We once again request you to inform us in the morning if you would like us to prepare the following. List of snacks available along with the price list is provided below:</p>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">

          {/* Single Product Area */}
          <div className="col-12 col-md-6 col-lg-6">
            <div className="single-rooms-area wow fadeInUp" data-wow-delay="100ms">
              {/* Thumbnail */}
              <div className="bg-thumbnail bg-img product-img-2"></div>
              {/* Price */}
              <p className="price-from">High Tea</p>
              {/* Product Text */}
              <div className="rooms-text">
              <h4> Our Snacks</h4>
              <div className="line"></div>
              
              {highTeaItems.map((item, index) => (
                  <div key={index} className="menu-item">
                    <p>
                      <strong>{item.name}</strong>: {item.price} <span>{item.description}</span>
                    </p>
                  </div>
                ))}
              </div>
              {/* Buy Product */}
              <a href="#" className="buy-product-btn btn palatin-btn">Buy Now</a>
            </div>
          </div>

          {/* Single Product Area */}
          <div className="col-12 col-md-6 col-lg-6">
            <div className="single-rooms-area wow fadeInUp" data-wow-delay="100ms">
              {/* Thumbnail */}
              <div className="bg-thumbnail bg-img product-img-1"></div>
              {/* Price */}
              <p className="price-from">Drinks</p>
              {/* Product Text */}
              <div className="rooms-text">
              <h4>Our Refreshments</h4>
                <div className="line"></div>
                
                {drinksItems.map((item, index) => (
                  <div key={index} className="menu-item">
                    <p>
                      <strong>{item.name}</strong>: {item.price} <span>{item.description}</span>
                    </p>
                  </div>
                ))}
              </div>
              {/* Buy Product */}
              <a href="#" className="buy-product-btn btn palatin-btn">Buy Now</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Products;
