const Thanks = ({ showModal, toggleModal }) => {
    const containerStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#f8f9fa',
      color: '#343a40',
      fontFamily: 'Arial, sans-serif',
    };
  
    const cardStyle = {
      backgroundColor: '#ffffff',
      padding: '40px',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      textAlign: 'center',
    };
     
    return (
    <section className="hero-area" style={{ marginTop: "80px"}}>
      <div style={containerStyle}>
        <div style={cardStyle}>
          <h1>Thank You!</h1>
          <p>Your Request for stay at Dudly Manor has been successfully shared.</p>
          <p>Our team will reach out to you soon with further details via email.</p>
          <div className="menu-btn">
                    <a href="https://www.dudlymanor.com/" className="btn palatin-btn">Return to website</a>
          </div>
        </div>
      </div>
    </section>
    );
  };
  
  export default Thanks;
  